import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useStaticQuery, graphql } from "gatsby"
const SimpleSlider = () => {
  const data = useStaticQuery(graphql`
    {
      allSanitySlider {
        nodes {
          title
          desc
          image {
            asset {
              url
            }
          }
        }
      }
      allSanitySlidertwo {
        nodes {
          title
          desc
          image {
            asset {
              url
            }
          }
        }
      }
      allSanitySliderthree {
        nodes {
          title
          desc
          image {
            asset {
              url
            }
          }
        }
      }
    }
  `)
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    fade: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  }
  return (
    <div className=" w-3/2 mx-auto mt-7 lg:w-6/12 ">
      <Slider {...settings}>
        <div className="w-4/5 mx-auto">
          {data.allSanitySlider.nodes.map(document => (
            <div>
              <h3 className="text-center text-yellow-700 text-2xl font-semibold lg:text-3xl">
                {document.title}
              </h3>
              <p className="text-xs w-2/3 mx-auto mb-4 mt-3 leading-4 text-gray-500 font-medium text-center lg:text-lg">
                {document.desc}
              </p>
              <img
                className="rounded-br-3xl rounded-tl-3xl  "
                src={document.image.asset.url}
                alt=""
              />
            </div>
          ))}
        </div>
        <div className="w-4/5 mx-auto">
          {data.allSanitySlidertwo.nodes.map(document => (
            <div>
              <h3 className="text-center text-yellow-700 text-2xl font-semibold lg:text-3xl">
                {document.title}
              </h3>
              <p className="text-xs w-2/3 mx-auto mb-4 mt-3 leading-4 text-gray-500 font-medium text-center lg:text-lg">
                {document.desc}
              </p>
              <img
                className="rounded-br-3xl rounded-tl-3xl  "
                src={document.image.asset.url}
                alt=""
              />
            </div>
          ))}
        </div>
        <div className="w-4/5 mx-auto">
          {data.allSanitySliderthree.nodes.map(document => (
            <div>
              <h3 className="text-center text-yellow-700 text-2xl font-semibold lg:text-3xl">
                {document.title}
              </h3>
              <p className="text-xs w-2/3 mx-auto mb-4 mt-3 leading-4 text-gray-500 font-medium text-center lg:text-lg">
                {document.desc}
              </p>
              <img
                className="rounded-br-3xl rounded-tl-3xl  "
                src={document.image.asset.url}
                alt=""
              />
            </div>
          ))}
        </div>
      </Slider>
    </div>
  )
}
export default SimpleSlider
